
























































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import { DisplayReport, RowData } from './types';
import moment from 'moment';
import axios from 'axios';
const excel = require('../libs/excel');
const _ = require('lodash');

interface RowDataReport extends RowData {
  time?: string;
}
export default Vue.extend({
  components: {
    DatePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    const date = moment().valueOf();
    return {
      display: false,
      form: {
        date,
        reportType: 'daily',
      },
      activeDateTime: 0,
    };
  },
  computed: {
    pickerType() {
      if (this.form.reportType === 'daily') return 'date';
      return 'month';
    },
    baseUrl(): string {
      return process.env.NODE_ENV === 'development'
        ? 'http://localhost:9000/'
        : '/';
    },
    getWaveBEImgUrl(): string {
      return `${this.baseUrl}WaveBE?dateTime=${this.activeDateTime}`;
    },
    getWaveBNImgUrl(): string {
      return `${this.baseUrl}WaveBN?dateTime=${this.activeDateTime}`;
    },
    getWaveBZImgUrl(): string {
      return `${this.baseUrl}WaveBZ?dateTime=${this.activeDateTime}`;
    },
    getWaveDEImgUrl(): string {
      return `${this.baseUrl}WaveDE?dateTime=${this.activeDateTime}`;
    },
    getWaveDNImgUrl(): string {
      return `${this.baseUrl}WaveDN?dateTime=${this.activeDateTime}`;
    },
    getWaveDZImgUrl(): string {
      return `${this.baseUrl}WaveDZ?dateTime=${this.activeDateTime}`;
    },
    getWaveEEImgUrl(): string {
      return `${this.baseUrl}WaveEE?dateTime=${this.activeDateTime}`;
    },
    getWaveENImgUrl(): string {
      return `${this.baseUrl}WaveEN?dateTime=${this.activeDateTime}`;
    },
    getWaveEZImgUrl(): string {
      return `${this.baseUrl}WaveEZ?dateTime=${this.activeDateTime}`;
    },
  },
  methods: {
    async query() {
      this.display = true;
      this.activeDateTime = this.form.date;
    },
  },
});
